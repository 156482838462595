<template>
    <Header :title="$tc('create_role', 1)" :backLinkText="$tc('back_to_role', 2)" :backLinkURL="{ name: 'roles' }" />

    <section class="mt-5">
        <form @submit.prevent="createRole">
            <div class="space-y-5">
                <Input :label="$tc('name', 1)" type="text" id="name" v-model="role.name" :error="errors.name" />
                <Textarea :label="$tc('description', 1)" type="text" id="description" v-model="role.description" :error="errors.description" />

                <div>
                    <label class="block text-sm font-medium text-gray-700">{{ $tc('permission', 2) }}</label>

                    <div class="mt-3 grid grid-cols-2 gap-y-5">
                        <div v-for="(permissionGroup, label) in permissions" :key="permissionGroup">
                            <p class="block capitalize text-sm font-medium text-gray-700 uppercase">{{ label }}</p>
                            <div class="mt-1 grid grid-cols-2 gap-y-1">
                                <Checkbox v-for="permission in permissionGroup" :key="permission" :label="permission.display_name" :value="permission.id" v-model="role.permission_ids" />
                            </div>
                        </div>
                    </div>

                    <p v-show="errors.permissions" class="mt-1 text-sm text-red-600">{{ errors.permissions }}</p>
                </div>

                <SubmitButton :label="$tc('create_role', 1)" submittingLabel="$tc('creating_role', 1)" />
            </div>
        </form>
    </section>
</template>

<script>
    export default {
        mounted() {
            this.$store.dispatch('roles/fetchPermissions');
        },
        data() {
            return {
                role: {
                    name: '',
                    description: '',
                    permission_ids: [],
                },
                errors: {},
            };
        },
        computed: {
            permissions() {
                return this.$store.getters['roles/permissions'];
            },
        },
        methods: {
            async createRole() {
                try {
                    this.errors = {};

                    if (!this.role.name) this.errors.name = this.$tc('required_field', 1, { field: this.$tc('name', 1) });
                    if (!this.role.description) this.errors.description = this.$tc('required_field', 1, { field: this.$tc('description', 1) });
                    if (this.role.permission_ids.length <= 0) this.errors.permissions = this.$tc('required_field', 1, { field: this.$tc('permission', 1) });

                    if (Object.keys(this.errors).length === 0) {
                        await this.$store.dispatch('roles/createRole', this.role);
                        this.$router.push({ name: 'roles' });
                    }
                } catch (err) {
                    console.log(err);
                }
            },
        },
    };
</script>
